var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{staticClass:"pb-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('div',[_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[_c('div',{staticClass:"grid gap-0 mt-8"},_vm._l((_vm.criteria),function(criterion,index){return _c('div',{key:criterion.id,staticClass:"grid gap-0"},[_c('cv-accordion',[_c('cv-accordion-item',[_c('template',{slot:"title"},[_c('h4',{staticClass:"text-xl font-bold"},[_vm._v(" "+_vm._s(criterion.title)+" ")])]),_c('template',{slot:"content"},[_c('RichTextDisplay',{attrs:{"text":criterion.instruction}}),_c('ValidationProvider',{staticClass:"w-full",attrs:{"tag":"div","rules":"required|max_value:10","name":("grade_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-number-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.#'),expression:"'##.#'"}],attrs:{"label":"Nota","invalid-message":errors[0]},model:{value:(criterion.givenGrade),callback:function ($$v) {_vm.$set(criterion, "givenGrade", $$v)},expression:"criterion.givenGrade"}})]}}],null,true)})],1)],2)],1)],1)}),0),_c('div',{staticClass:"w-full lg:w-4/5"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),_c('p',{staticClass:"w-full"},[_vm._v(" Todos os campos acima são obrigatórios. ")]),(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])}),_c('cv-modal',{attrs:{"visible":true}},[_c('template',{slot:"title"},[_vm._v(" Orientações ")]),_c('template',{slot:"content"},[(_vm.instructions.length)?_c('RichTextDisplay',{attrs:{"text":_vm.instructions}}):_vm._e()],1),_c('template',{slot:"primary-button"},[_vm._v(" Estou ciente das Orientações ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }