




































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'

import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import RichTextDisplay from '@/partials/components/RichTextDisplay.vue'

@Component({
  directives: { mask },
  components: {
    RichTextDisplay,
    PasswordConfirmField,
    NotifyOnFailed,
    ValidationProvider,
    ValidationObserver
  }
})
export default class EventStudyForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ required: true, type: String }) studyId!: string
  @Prop({ required: true, type: String }) reviewTypeId!: string
  @Prop({ type: String }) reviewId!: string
  @Prop({ type: String }) type!: 'create' | 'edit'

  done = true
  attachment = ''
  form: Record<string, any> = {
    reviewerId: '',
    confirm: '',
    grades: [],
    errors: {
      reviewerId: '',
      confirm: ''
    }
  }

  criteria = [] as any
  instructions = ''

  created () {
    axios.get(`/event/${this.eventId}/study-review-type/${this.reviewTypeId}`)
      .then(response => {
        this.instructions = response.data.data.body
      })
    axios.get(`/event/${this.eventId}/study-review-type/${this.reviewTypeId}/criteria`)
      .then(response => {
        this.criteria = camelCaseKeys(response.data.data, { deep: true })
          .map((criterion: any) => {
            return {
              ...criterion,
              givenGrade: ''
            }
          })
      })
  }

  resetErrors () {
    this.form.errors = {
      title: '',
      abstract: '',
      body: '',
      attachment: '',
      eventId: '',
      topics: '',
      confirm: ''
    }
  }

  submit () {
    this.done = false
    const verb = 'post'
    const route = `/event/${this.eventId}/study/${this.studyId}/reviews`

    axios[verb](route, snakeCaseKeys(this.submitForm))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  get submitForm () {
    return {
      reviewerId: '830784fe-62bc-44f5-b76a-d436e95740ed',
      reviewerName: 'DEPRECATED',
      eventStudyId: this.studyId,
      eventStudyReviewTypeId: this.reviewTypeId,
      confirm: this.form.confirm,
      grades: this.criteria.map((criterion: any) => {
        return {
          criterionId: criterion.id,
          grade: criterion.givenGrade
        }
      }) ?? []
    }
  }

  redirectOnSuccess () {
    const flashMessage = {
      message: 'A Avaliação foi cadastrada com sucesso.',
      kind: 'success'
    }

    this.$router.push({ name: 'EventStudyReviews', params: { id: this.eventId } }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }
}
