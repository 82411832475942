

















import { Component, Vue } from 'vue-property-decorator'

import EventStudyReviewForm from '@/partials/forms/Events/StudyReviewForm.vue'
import EventStudyDisplay from '@/partials/components/Events/StudyDisplay.vue'

@Component({
  components: {
    EventStudyReviewForm,
    EventStudyDisplay
  }
})
export default class EventStudyReviewCreate extends Vue {

}
