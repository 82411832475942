




































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import Download16 from '@carbon/icons-vue/es/download/16'

import RichTextDisplay from '@/partials/components/RichTextDisplay.vue'

@Component({
  components: {
    Download16,
    RichTextDisplay
  }
})
export default class EventStudyDisplay extends Vue {
  @Prop({ type: String, required: true }) eventId!: string
  @Prop({ type: String, required: true }) studyId!: string

  study = {
    title: '',
    abstract: '',
    body: '',
    attachment: {
      publicPath: ''
    }
  }

  done = false

  created () {
    axios.get(`/event/${this.eventId}/study/${this.studyId}`)
      .then(response => {
        this.study = camelCaseKeys(response.data.data, { deep: true })
        this.done = true
      })
  }
}
